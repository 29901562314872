<template>
  <header id="header" ref="header" class="header" :class="{ 'header--sticky': sticky }">
    <div class="flex w-full">
      <Button url="/" kind="link">
        <div class="flex place-content-center place-items-center header__logo">
          <svg
            viewBox="0 0 13 13"
            height="30"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            class="2xl:hidden"
          >
            <path d="M.896 12V.48h2.56l6.448 7.68V.48h2.512V12h-2.56l-6.48-7.712V12H.896Z" />
          </svg>

          <svg
            height="20"
            viewBox="0 0 82 12"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            class="hidden 2xl:block"
          >
            <path
              d="M0.896 12V0.48H3.456L9.904 8.16V0.48H12.416V12H9.856L3.376 4.288V12H0.896ZM14.2405 12V0.48H24.8805V2.976H16.7525V4.992H23.2965V7.488H16.7525V9.504H24.8805V12H14.2405ZM30.3945 12V2.976H25.8825V0.48H37.4025V2.976H32.8905V12H30.3945ZM42.4551 12L38.2631 0.48H40.9031L43.4151 7.344L45.9271 0.48H48.2151L50.7431 7.344L53.2551 0.48H55.8791L51.6871 12H49.7831L47.0631 4.592L44.3591 12H42.4551ZM57.4425 12V0.495999H66.5785C67.2292 0.495999 67.7892 0.730666 68.2585 1.2C68.7278 1.66933 68.9625 2.224 68.9625 2.864V5.84C68.9625 6.48 68.7278 7.03467 68.2585 7.504C67.7892 7.97333 67.2292 8.208 66.5785 8.208H66.4665L68.9625 11.152V12H66.4025L63.2185 8.208L59.9225 8.224V12H57.4425ZM60.4505 5.712H65.9225C66.2745 5.712 66.4505 5.712 66.4505 5.712C66.4505 5.712 66.4505 5.536 66.4505 5.184V3.504C66.4505 3.152 66.4505 2.976 66.4505 2.976C66.4505 2.976 66.2745 2.976 65.9225 2.976H60.4505C60.0985 2.976 59.9225 2.976 59.9225 2.976C59.9225 2.976 59.9225 3.152 59.9225 3.504V5.184C59.9225 5.536 59.9225 5.712 59.9225 5.712C59.9225 5.712 60.0985 5.712 60.4505 5.712ZM70.6776 12V0.48H73.1736V4.992H75.4296L79.2056 0.48H81.7976V1.344L77.6856 6.24L81.7976 11.136V12H79.2056L75.4296 7.488H73.1736V12H70.6776Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </Button>

      <nav class="flex gap-4">
        <Button url="/dashboard" kind="link" class="text-base">Dashboard</Button>

        <Button
          v-if="netwrkCampaigns || netwrkTeams"
          class="text-base"
          :url="adminOrganization ? '/admin' : '/streamers'"
          kind="link"
        >
          {{ adminOrganization ? "Admin" : "Streamers" }}
        </Button>

        <Button v-if="contentCreator" url="/page-builder" class="text-base" kind="link">Creator Page</Button>

        <Button v-if="contentCreator" class="text-base" :url="`/profile/${getUserInfo.username}`" kind="link">
          Profile
        </Button>

        <Button v-if="contentCreator || netwrkTeams" class="text-base" url="/wallet" kind="link">Wallet</Button>

        <Button v-if="contentCreator" class="text-base" url="/settings" kind="link">Settings</Button>
      </nav>

      <div class="flex gap-6 content-center items-center ml-auto">
        <Notification />

        <Button v-if="netwrkCampaigns || admin" kind="primary" @clicked="newCampaign">New campaign</Button>

        <OrgSwitcher v-if="getOrganizations && getOrganizations.length > 1" />

        <router-link
          :to="
            getUserInfo && getUserInfo.username && getUserInfo.type === 'streamer'
              ? `/profile/${getUserInfo.username.toLowerCase()}`
              : '#'
          "
        >
          <Avatar
            v-if="getUserInfo && getUserInfo.username"
            :src="`https://storage.googleapis.com/cavea-avatar-service/twitch/${getUserInfo.username}/avatar.png`"
            size="default"
            @error="profileImageFallback"
          />
        </router-link>

        <Button
          class="text-base capitalize"
          kind="link"
          :label="getUserInfo.fullname || getUserInfo.fullName || getUserInfo.username"
          :url="
            getUserInfo && getUserInfo.username && getUserInfo.type === 'streamer'
              ? `/profile/${getUserInfo.username.toLowerCase()}`
              : '#'
          "
        />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { Avatar, Button } from "cavea-design-system";

export default {
  name: "Header",

  components: {
    Button,
    Avatar,
    Notification: () => import("@/components/Notification"),
    OrgSwitcher: () => import("@/components/OrgSwitcher"),
  },

  data() {
    return {
      sticky: false,
    };
  },

  computed: {
    ...mapGetters(["getUserInfo", "getOrganization", "getOrganizations"]),

    contentCreator() {
      return this.getUserInfo?.type === "streamer";
    },

    netwrkTeams() {
      return this.getOrganization?.settings?.products?.netwrk_teams;
    },

    netwrkCampaigns() {
      return this.getOrganization?.settings?.products?.netwrk_campaigns;
    },

    admin() {
      return this.getUserInfo.type === "admin";
    },

    adminOrganization() {
      return this.getOrganization?._id === "6104316858d6180c099272b9";
    },
  },

  methods: {
    ...mapMutations(["resetNewCampaign"]),

    /**
     * @summary reset campaign state to clean model & goto new campaign
     */
    newCampaign() {
      this.resetNewCampaign();

      this.$router.push("/create/campaign/settings");
    },

    profileImageFallback(event) {
      event.target.src = "https://storage.googleapis.com/cavea-avatar-service/twitch/frymealiiiver/avatar.png";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/globals";
@import "../styles/helpers";
@import "../styles/colors";

.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  left: 0;
  background-color: color(blue, darker);
  z-index: 1050;
  height: 5rem;
  width: 100%;
}

.header__logo {
  margin-right: 2rem;
  height: 1rem;
  color: color(black, base);

  > img {
    height: 1rem;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.c-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.nav {
  height: 100%;
}

.nav__list {
  display: flex;
  height: 100%;
}

.nav__item {
  height: 100%;

  &:not(:first-child) {
    margin-left: 3rem;
  }
}

.nav__button {
  height: 100%;
  display: flex;
  align-items: center;

  &.router-link-active {
    color: color(cta, primary);
  }
}

.header__name {
  @media (max-width: 1024px) {
    display: none;
  }
}
</style>
